// Neat cards from:
// https://dev.to/franciscomendes10866/how-to-create-modern-cards-using-react-and-tailwind-2ded

import classNames from "classnames";

import styles from "./GridCard.module.css";

const GridCard = ({title, data, fullWidth}) => {

  return (
    <div className={classNames([styles.gridCard, styles.gridCardAnimation])}>
      <div className={styles.gridCardHeader}>
        {fullWidth === true ? (
          <div className="flex flex-col">
          { data.map((item, i) =>
            <div key={i} className={styles.gridFullCardItem}>
              <div className={styles.gridCardItemTitle}>{item}</div>
            </div>
          )}
          </div>
        ) : (
          <div className="flex flex-wrap place-items-center justify-items-center justify-around items-stretch">
            { data.map((item, i) =>
              <div key={i} className={styles.gridCardItem}>
                <div className={styles.gridCardItemTitle}>{item}</div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.gridCardTitleWrapper}>
        <h1 className={styles.gridCardTitle}>{title}</h1>
      </div>
    </div>
  );
};

export default GridCard;
