import { useState } from 'react';
import Atlas from './components/atlas';
import InfoPanel from './components/info-panel';
import './App.css';

const App = () => {
  const [uiActive, setUiActive] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState(null);

  const updateCountryId = (countryId) => {
    if (selectedCountryId !== countryId) {
      setSelectedCountryId(countryId);
    }
    if (countryId !== null) setUiActive(true);
  };

  const closeUI = () => {
    setUiActive(false);
  };

  return (
    <div className="App">
      <Atlas countrySelectCallback={updateCountryId} uiActive={uiActive} />
      {uiActive && selectedCountryId && <InfoPanel countryId={selectedCountryId} closeUICallback={closeUI} /> }
    </div>
  );
};

export default App;