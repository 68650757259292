// Neat cards from:
// https://dev.to/franciscomendes10866/how-to-create-modern-cards-using-react-and-tailwind-2ded

import { useState, useEffect } from 'react';

import classNames from "classnames";
import { BsHexagonFill } from "react-icons/bs";
import { FcTimeline } from "react-icons/fc";

import styles from "./DataCard.module.css";

const DataCard = ({ title, data }) => {
  const [dataDisplayIndex, setDisplayIndex] = useState(0);
  const [displayData, setDisplayData] = useState(null);

  useEffect(() => {
    if (data != null && data.constructor === Array) {
      if (data.every((item) => item != null && item.year != null)) {
        let sortedData = data.sort((a, b) => b.year - a.year);
        setDisplayData(sortedData);
      } else if (data.length === 1) {
        setDisplayData(data);
      }
      setDisplayIndex(0);
    }
  }, [data]);

  // const handleYearSelection = (selectionIndex) => {
  //   setDisplayIndex(selectionIndex);
  // }

  const convertNumber = (value) => {
    if (value == null || value.constructor !== Number) {
      return "No. -_-";
    }

    if (Math.abs(value) >= 1.0e+12) {
      let x = (value / 1.0e+12).toFixed(1) + " trillion";
      return x;
    } else if (Math.abs(value) >= 1.0e+9) {
      let x = (value / 1.0e+9).toFixed(1) + " billion";
      return x;
    } else if (Math.abs(value) >= 1.0e+6) {
      let x = (value / 1.0e+6).toFixed(1) + " million";
      return x;
    } else {
      return value.toLocaleString("en-US");
    }
  }

  return (
    <div> { displayData != null && displayData[dataDisplayIndex].value != null &&
      <div className={classNames([styles.dataCard, styles.dataCardAnimation])}>
        <div className={styles.dataCardHeader}>
          <div className={styles.dataCardValueWrapper}>
            <span className={styles.dataCardValue}>{convertNumber(displayData[dataDisplayIndex].value)}</span>
          </div>
          <img src={"https://bit.ly/3BQdTqk"} className={styles.dataCardImage} alt="" />
          <div className={styles.dataCardBadgeContainer}>
            {displayData[dataDisplayIndex].year != null &&
              <div className={classNames([styles.dataCardYearBadge, styles.dataCardBadgeAnimation, "group"])}>
                <FcTimeline />
                <span className={classNames([styles.mccounter, "group-hover:text-white"])}>
                  {displayData[dataDisplayIndex].year}
                </span>
              </div>
            }
            {displayData[dataDisplayIndex].unit != null &&
              <div className={classNames([styles.dataCardBadge, styles.dataCardBadgeAnimation, "group"])}>
                <BsHexagonFill />
                <span className={classNames([styles.mccounter, "group-hover:text-white"])}>
                  {displayData[dataDisplayIndex].unit}
                </span>
              </div>}
          </div>
        </div>
        <div className={styles.dataCardTitleWrapper}>
          <h1 className={styles.dataCardTitle}>{title}</h1>
        </div>
      </div>
    } </div>
  );
};

export default DataCard;