import DataCard from '../data-card';
import GridCard from '../grid-card';

const InfoDemographics = ({countryData}) => {

  return (
    <div className="container w-full h-full px-4 py-4">
      <div className="grid grid-cols-3">
        {countryData.population != null && <DataCard title="Population" data={countryData.population} /> }
        {countryData.median_age != null && <DataCard title="Median Age" data={countryData.median_age} /> }
        {countryData.population_growth_rate != null && <DataCard title="Pop. Growth Rate" data={countryData.population_growth_rate} /> }

        {countryData.language != null && <GridCard title="Languages" data={countryData.language} /> }
        {countryData.ethnic_group != null && <GridCard title="Ethnic Groups" data={countryData.ethnic_group} /> }
        {countryData.religion != null && <GridCard title="Religions" data={countryData.religion} /> }

        {countryData.population_urban != null && <DataCard title="Urban Population" data={countryData.population_urban} /> }
        {countryData.infant_mortality_rate != null && <DataCard title="Infant Mortality Rate" data={countryData.infant_mortality_rate} /> }
        {countryData.life_expectancy != null && <DataCard title="Life Expectancy" data={countryData.life_expectancy} /> }
        {countryData.fertility_rate != null && <DataCard title="Fertility Rate" data={countryData.fertility_rate} /> }
      </div>
    </div>
  );
};

export default InfoDemographics;
