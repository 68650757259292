import { useState, useEffect, useMemo, useRef } from 'react';
import Globe from 'react-globe.gl';
import * as d3 from 'd3';

const Atlas = ({countrySelectCallback, uiActive}) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const globeEl = useRef();
  const [countryPolygons, setCountryPolygons] = useState({ features: []});
  const [hoverD, setHoverD] = useState();
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    // Handle window resize
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetch('./country_polygons.geojson').then(res => res.json()).then(setCountryPolygons);
  }, []);

  // Set map colors (useMemo must be applied on component base layer with other hooks)
  const colorScale = d3.scaleSequentialSqrt(d3.interpolateYlOrRd);
  const getVal = feat => feat.properties.GDP_MD_EST / Math.max(1e5, feat.properties.POP_EST); // GDP per capita (avoiding countries with small pop)
  const maxVal = useMemo(
    () => Math.max(...countryPolygons.features.map(getVal)),
    [countryPolygons]
  );
  colorScale.domain([0, maxVal]);

  const handlePolygonClick = (polygon, event) => {
    console.log('Polygon clicked...');
    let country = polygon.properties;
    globeEl.current.pointOfView({'lat': country.LATITUDE, 'lng': country.LONGITUDE});
    setSelectedCountry(country);
    countrySelectCallback(country.ISO_A2);
  };

  const handleGlobeClick = ({ lat, lng }, event) => {
    setSelectedCountry(null);
    countrySelectCallback(null);
  };

  const globeShiftAmount = () => {
    if (uiActive && selectedCountry !== null) {
      return 0.55 * windowSize.width;
    } else {
      return 0;
    }
  }

  return (
    <div style={{marginLeft: `-${globeShiftAmount()}px`}}>
      <Globe
        ref={globeEl}
        width={windowSize.width + globeShiftAmount()}
        height={windowSize.height}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
        backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
        lineHoverPrecision={0}
        polygonsData={countryPolygons.features.filter(d => d.properties.ISO_A2 !== 'AQ')}
        polygonAltitude={d => d === hoverD ? 0.30 : 0.06}
        polygonCapColor={d => d === hoverD ? 'steelblue' : colorScale(getVal(d))}
        polygonSideColor={() => 'rgba(0, 100, 0, 0.15)'}
        polygonStrokeColor={() => '#111'}
        polygonLabel={({ properties: d }) => `<b>${d.NAME}</b>`}
        onPolygonHover={setHoverD}
        onPolygonClick={handlePolygonClick}
        polygonsTransitionDuration={300}
        onGlobeClick={handleGlobeClick}
      />
    </div>
  );
};

export default Atlas;