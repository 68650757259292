// Neat cards from:
// https://dev.to/franciscomendes10866/how-to-create-modern-cards-using-react-and-tailwind-2ded

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import * as d3 from "d3-scale-chromatic";
// import { BsHexagonFill } from "react-icons/bs";
// import { FcTimeline } from "react-icons/fc";


import interpolateColors from '../../utils/color-generator';

import styles from "./DonutCard.module.css";

ChartJS.register(ArcElement, Tooltip);

const DonutCard = ({title, data}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [chartData, setChartData] = useState(null);

  const options = {
    plugins: {
      tooltip: false
    },
    onHover: (event, element) => {
      if (element.length > 0) {
        let i = element[0].index;
        if (selectedIndex !== i) {
          setSelectedIndex(i);
        };
      }
    }
    // onClick: function(event, element) {
    // }
  };

  const convertNumber = (value) => {
    if (value == null || value.constructor !== Number) {
      return "No. -_-";
    }

    if (Math.abs(value) >= 1.0e+12) {
      let x = (value / 1.0e+12).toFixed(1) + " trillion";
      return x;
    } else if (Math.abs(value) >= 1.0e+9) {
      let x = (value / 1.0e+9).toFixed(1) + " billion";
      return x;
    } else if (Math.abs(value) >= 1.0e+6) {
      let x = (value / 1.0e+6).toFixed(1) + " million";
      return x;
    } else {
      return value.toLocaleString("en-US");
    }
  }

  useEffect(() => {
    let keys = Object.keys(data);
    const items = Object.values(data);

    keys = keys.map((key) => {
      key = key.replace(/_/g, ' ');
      key = key.split(' ').map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      });
      return key.join(' ');
    });

    const colorRangeInfo = {
      colorStart: 0,
      colorEnd: 1,
      useEndAsStart: true
    };

    const CHART_COLORS = interpolateColors(keys.length, d3.interpolateRainbow, colorRangeInfo);

    setChartData({
      labels: keys,
      datasets: [
        {
          data: items,
          backgroundColor: CHART_COLORS,
          hoverBackgroundColor: CHART_COLORS,
          cutout: '75%'
        },
      ]
    });
  }, [data]);

  // const initChartData = (data) => {
  //   if (data != null && chartData !== data) {
  //     setChartData(data);
  //   }
  // };

  return (
    <div>
      { chartData &&
      <div className={classNames([styles.donutCard, styles.donutCardAnimation])}>
        <div className={styles.donutCardChartFrame}>
          <Doughnut
            className={styles.donutCardChart}
            options={options}
            data={chartData} />
          <div className={styles.donutCardItemFrame}>
            <div className={styles.donutCardItemValue}>
              {convertNumber(chartData.datasets[0].data[selectedIndex].value)}
              {chartData.datasets[0].data[selectedIndex].unit === '%' &&
                chartData.datasets[0].data[selectedIndex].unit
              }
            </div>
            <div className={styles.donutCardItemTitle}>
              {chartData.labels[selectedIndex]}
            </div>
          </div>
        </div>

        <div className={styles.donutCardTitleWrapper}>
          <h1 className={styles.donutCardTitle}>{title}</h1>
        </div>
      </div>
      }
    </div>
  );
};

export default DonutCard;
