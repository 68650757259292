import DataCard from '../data-card';
import GridCard from '../grid-card';
import DonutCard from '../donut-card';

const InfoEnvironment = ({countryData}) => {

  return (
    <div className="container w-full h-full px-4 py-4 space-y-4">
      {countryData.land_use != null &&
        <div className="w-full shadow stats">
          <div className="stat place-items-center place-content-center">
            <div className="stat-title">Agriculture</div>
            <div className="stat-value">{countryData.land_use.agricultural_land.value} %</div>
          </div> 
          <div className="stat place-items-center place-content-center">
            <div className="stat-title">Forestry</div>
            <div className="stat-value text-success">{countryData.land_use.forest.value} %</div>
          </div> 
          <div className="stat place-items-center place-content-center">
            <div className="stat-title">Other</div>
            <div className="stat-value text-error">{countryData.land_use.other.value} %</div>
          </div>
        </div>
      }

      {countryData.climate_descriptor != null && <GridCard title="Climate" data={countryData.climate_descriptor} fullWidth={true} /> }
      {countryData.terrain_descriptor != null && <GridCard title="Terrain" data={countryData.terrain_descriptor}  /> }

      <div className="grid grid-cols-3">
        {countryData.renewable_water != null && <DataCard title="Renewable Water" data={countryData.renewable_water} /> }
        {countryData.revenue_forestry != null && <DataCard title="Forestry Revenue" data={countryData.revenue_forestry} /> }
        {countryData.revenue_coal != null && <DataCard title="Coal Revenue" data={countryData.revenue_coal} /> }
      </div>

      {countryData.natural_resource != null && <GridCard title="Natural Resources" data={countryData.natural_resource} /> }
      {countryData.environmental_issue != null && <GridCard title="Environmental Issues" data={countryData.environmental_issue} /> }

      {countryData.air_pollution &&
        <div className="w-1/2 inline-block p-2">
          <DonutCard title="Air Pollution" data={countryData.air_pollution} />
        </div>
      }
      {countryData.waste &&
        <div className="w-1/2 inline-block p-2">
          <DonutCard title="Waste" data={countryData.waste} />
        </div>
      }
    </div>
  );
};

export default InfoEnvironment;
