import { useState, useEffect } from 'react';
import classNames from 'classnames';

import styles from '../data-card/DataCard.module.css';
import DataCard from '../data-card';
import TextCard from '../text-card';

const InfoHome = ({countryData}) => {
  const [iso3316a2, setISO3316A2] = useState(null);

  useEffect(() => {
    if (countryData !== null && countryData.iso_3316_a2 !== undefined) {
      console.log(countryData.iso_3316_a2);
      setISO3316A2(countryData.iso_3316_a2.toLowerCase());
    }
  }, [countryData]);

  return (
    <div className="container w-full h-full px-4 py-4 space-y-4">
      <div className="grid grid-cols-3 grid-flow-row">
        <div className="grid-col col-span-1">
          <div className={classNames([styles.flagCard, styles.dataCardAnimation])}>
            <img
              className="p-6"
              src={`https://flagcdn.com/h240/${iso3316a2}.jpg`}
              alt={`${countryData.name}`} />
          </div>
          {countryData.population != null && <DataCard title="Population" data={countryData.population} />}
        </div>
        {countryData.country_descriptor != null &&
          <div className="col-span-2">
            <TextCard text={countryData.country_descriptor} />
          </div>
        }

        <div className="grid grid-cols-4 grid-flow-row col-span-full">
          {countryData.area && <DataCard title="Total Area" data={countryData.area} />}
          {countryData.area_land && <DataCard title="Area (Land)" data={countryData.area_land}/>}
          {countryData.area_water && <DataCard title="Area (Water)" data={countryData.area_water} />}
          {countryData.coastline && <DataCard title="Coastline" data={countryData.coastline} />}
        </div>
      </div>

    </div>
  );
};

export default InfoHome;
