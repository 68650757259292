import DataCard from '../data-card';
import GridCard from '../grid-card';
import DonutCard from '../donut-card';

const InfoInfrastructure = ({countryData}) => {

  return (
    <div className="container w-full h-full px-4 py-4 space-y-4">
      <div className="grid grid-cols-3">
        {countryData.airports && <DataCard title="Airports" data={countryData.airports} />}
        {countryData.heliports && <DataCard title="Heliports" data={countryData.heliports} />}
        {countryData.railways && <DataCard title="Railways" data={countryData.railways} />}
        {countryData.roadways && <DataCard title="Roadways" data={countryData.roadways} />}
        {countryData.waterways && <DataCard title="Waterways" data={countryData.waterways} />}
        {countryData.electricity_access && <DataCard title="Access to Electricity" data={countryData.electricity_access} />}
        {countryData.electricity_production && <DataCard title="Electricity Production" data={countryData.electricity_production} />}
        {countryData.electricity_consumption && <DataCard title="Electricity Consumption" data={countryData.electricity_consumption} />}
        {countryData.electricity_import && <DataCard title="Electricity Import" data={countryData.electricity_import} />}
        {countryData.electricity_export && <DataCard title="Electricity Export" data={countryData.electricity_export} />}
      </div>

      {countryData.electricity_sources &&
        <div className="w-1/2 inline-block p-2">
          <DonutCard title="Electricity Sources" data={countryData.electricity_sources} />
        </div>
      }
      {countryData.energy_source &&
        <div className="w-1/2 inline-block p-2">
          <DonutCard title="Energy Sources" data={countryData.energy_source} />
        </div>
      }

      {countryData.telecom_descriptor && <GridCard title="Telecommunications" data={countryData.telecom_descriptor} />}
    </div>
  );
};

export default InfoInfrastructure;