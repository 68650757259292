import TextCard from '../text-card';
import GridCard from '../grid-card';

const InfoGovernment = ({countryData}) => {

  return (
    <div className="container w-full h-full px-4 py-4">
      {countryData.government_type != null && <TextCard title='Government Type' text={countryData.government_type} /> }

      <div className="grid grid-cols-4">
        <div className="col-span-2">
          {countryData.chief_of_state != null && <GridCard title="Chiefs of State" data={countryData.chief_of_state} /> }
        </div>
        <div className="col-span-2">
          {countryData.head_of_government != null && <GridCard title="Heads of Government" data={countryData.head_of_government} /> }
        </div>
        {countryData.legal_system_descriptor != null && <GridCard title="Legal Systems" data={countryData.legal_system_descriptor} fullWidth={true} /> }
        {countryData.political_party != null && <GridCard title="Political Parties" data={countryData.political_party} /> }
        {countryData.dependent_territory != null && <GridCard title="Dependent Territories" data={countryData.dependent_territory} /> }
      </div>
    </div>
  );
};

export default InfoGovernment;
