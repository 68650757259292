import DataCard from '../data-card';
import GridCard from '../grid-card';
import DonutCard from '../donut-card';

const InfoEconomy = ({countryData}) => {

  return (
    <div className="container w-full h-full px-4 py-4 space-y-4">
      <div className="grid grid-cols-3">
        {countryData.gini_index_coefficient && <DataCard title="GINI Index" data={countryData.gini_index_coefficient} />}
        {countryData.inflation_rate && <DataCard title="Inflation Rate" data={countryData.inflation_rate} />}
        {countryData.gdp_purchasing_power && <DataCard title="GDP Purchasing Power" data={countryData.gdp_purchasing_power} />}
        {countryData.gdp_growth_rate && <DataCard title="GDP Growth Rate" data={countryData.gdp_growth_rate} />}
        {countryData.gdp_exchange_rate && <DataCard title="GDP Exchange Rate" data={countryData.gdp_exchange_rate} />}
        {countryData.gdp_per_capita && <DataCard title="GDP (per Capita)" data={countryData.gdp_per_capita} />}
      </div>

      {countryData.industry && <GridCard title="Industries" data={countryData.industry} />}
      {countryData.industrial_growth_rate && <DataCard title="Industrial Growth Rate" data={countryData.industrial_growth_rate} />}
      <div className="grid grid-cols-2">
        {countryData.exports_usd && <DataCard title="Exports" data={countryData.exports_usd} className="col-span-2" />}
        {countryData.imports_usd && <DataCard title="Imports" data={countryData.imports_usd} className="col-span-2" />}
      </div>
      {countryData.export_commodity && <GridCard title="Export Commodities" data={countryData.export_commodity} />}
      {countryData.import_commodity && <GridCard title="Import Commodities" data={countryData.import_commodity} />}
      {countryData.agricultural_commodity && <GridCard title="Agricultural Commodities" data={countryData.agricultural_commodity} />}

      {countryData.gdp_composition &&
        <div className="w-1/2 inline-block p-2">
          <DonutCard title="GDP Composition" data={countryData.gdp_composition} />
        </div>
      }
      {countryData.labor_force_distribution &&
        <div className="w-1/2 inline-block p-2">
          <DonutCard title="Labor Force Distribution" data={countryData.labor_force_distribution} />
        </div>
      }
      <div className="grid grid-cols-3">
        {countryData.labor_force && <DataCard title="Labor Force" data={countryData.labor_force} />}
        {countryData.population_below_poverty && <DataCard title="Pop. Below Poverty" data={countryData.population_below_poverty} />}
        {countryData.unemployment_rate && <DataCard title="Unemployment Rate" data={countryData.unemployment_rate} />}
        {countryData.unemployment_youth && <DataCard title="Youth Unemployment Rate" data={countryData.unemployment_youth} />}
        {countryData.gdp_expenditure_education && <DataCard title="Education Expenditure" data={countryData.gdp_expenditure_education} />}
        {countryData.gdp_expenditure_health && <DataCard title="Healthcare Expenditure" data={countryData.gdp_expenditure_health} />}
      </div>
    </div>
  );
};

export default InfoEconomy;
