import { useState, useEffect } from 'react';
import InfoHome from './home';
import InfoEnvironment from './environment';
import InfoDemographics from './demographics';
import InfoInfrastructure from './infrastructure';
import InfoEconomy from './economy';
import InfoGovernment from './government';


const InfoPanel = ({closeUICallback, countryId}) => {
  const [activeTab, setActiveTab] = useState('home');
  const [countryData, setCountryData] = useState(null);


  useEffect(() => {
    if (countryId !== null) {
      const countryFile = './data/countries/' + countryId + '.json';
      fetch(countryFile).then(res => res.json()).then(res => {
        setCountryData(res);
        console.log(res);
      });
    }
  }, [countryId]);


  return (
    <div>
      {countryData !== null ? (
        <div className="UI-Container container flex items-center px-4 py-4 card">
          {/* Navigation Bar */}
          <div className="navbar mb-2 shadow-lg bg-neutral text-neutral-content rounded-box w-full">
            <div className="px-2 mx-2 navbar-start">
              {countryData !== null && countryData.name_formal ? (
                <span className="text-lg font-bold">{countryData.name_formal}</span>
              ) : (
                <span className="text-lg font-bold">{countryData.name}</span>
              )}
            </div>
            <div className="hidden px-2 mx-2 navbar-center lg:flex">
              <div className="flex items-stretch">
                <button className="btn btn-ghost btn-sm rounded-btn selected" onClick={() => {setActiveTab('home')}}>Home</button>
                <button className="btn btn-ghost btn-sm rounded-btn" onClick={() => {setActiveTab('government')}}>Government</button>
                <button className="btn btn-ghost btn-sm rounded-btn" onClick={() => {setActiveTab('demographics')}}>Demographics</button>
                <button className="btn btn-ghost btn-sm rounded-btn" onClick={() => {setActiveTab('infrastructure')}}>Infrastructure</button>
                <button className="btn btn-ghost btn-sm rounded-btn" onClick={() => {setActiveTab('economy')}}>Economy</button>
                <button className="btn btn-ghost btn-sm rounded-btn" onClick={() => {setActiveTab('environment')}}>Environment</button>
              </div>
            </div>
            <div className="navbar-end">
              <button className="btn btn-square btn-ghost" onClick={() => {closeUICallback()}}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          </div>

          {/* Scrollable UI Container */}
          <div className="container h-full w-full overflow-y-auto no-scrollbar">
            { activeTab === 'home' && <InfoHome countryData={countryData} />}
            { activeTab === 'government' && <InfoGovernment countryData={countryData} />}
            { activeTab === 'demographics' && <InfoDemographics countryData={countryData} />}
            { activeTab === 'infrastructure' && <InfoInfrastructure countryData={countryData} />}
            { activeTab === 'economy' && <InfoEconomy countryData={countryData} />}
            { activeTab === 'environment' && <InfoEnvironment countryData={countryData} />}
          </div>
        </div>
      ) : (
        <div className="UI-Container container flex items-center px-4 py-4 card bg-base-200">
          {/* Navigation Bar */}
          <div className="navbar mb-2 shadow-lg bg-neutral text-neutral-content rounded-box w-full">
            <div className="px-2 mx-2 navbar-start">
              {countryData !== null && <span className="text-lg font-bold">{countryData.name_formal}</span>}
            </div>
            <div className="hidden px-2 mx-2 navbar-center lg:flex">
              <div className="flex items-stretch">
                <button className="btn btn-ghost btn-sm rounded-btn selected" onClick={() => {setActiveTab('home')}}>Home</button>
                <button className="btn btn-ghost btn-sm rounded-btn" onClick={() => {setActiveTab('government')}}>Government</button>
                <button className="btn btn-ghost btn-sm rounded-btn" onClick={() => {setActiveTab('demographics')}}>Demographics</button>
                <button className="btn btn-ghost btn-sm rounded-btn" onClick={() => {setActiveTab('infrastructure')}}>Infrastructure</button>
                <button className="btn btn-ghost btn-sm rounded-btn" onClick={() => {setActiveTab('economy')}}>Economy</button>
                <button className="btn btn-ghost btn-sm rounded-btn" onClick={() => {setActiveTab('environment')}}>Environment</button>
              </div>
            </div>
            <div className="navbar-end">
              <button className="btn btn-square btn-ghost" onClick={() => {closeUICallback()}}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          </div>

          {/* Scrollable UI Container */}
          <div className="container h-full w-full space-y-4 overflow-y-auto">
            <p>No data to display!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoPanel;
