// Neat cards from:
// https://dev.to/franciscomendes10866/how-to-create-modern-cards-using-react-and-tailwind-2ded

import classNames from "classnames";

import styles from "./TextCard.module.css";

const TextCard = ({title, text}) => {

  return (
    <div className={classNames([styles.textCard, styles.textCardAnimation])}>
      {text != null &&
        <div className={styles.textCardDisplay}>
          <div className={styles.textCardDisplayText} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      }
      {title != null &&
        <div className={styles.textCardTitleWrapper}>
          <h1 className={styles.textCardTitle}>{title}</h1>
        </div>
      }
    </div>
  );
};

export default TextCard;